body {
  overflow-x: hidden;
  font-family: 'Poppins' !important;
  /* background-color: #F5F5F5; */
}

* {
  padding: 0px;
  margin: 0px;
}

.txt-center {
  text-align: center;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.input-span {
  position: absolute;
  top: -30px;
  color: red;
  left: 50px;
}






/* Login Area Start */

.login-Area {
  margin-left: 0px !important;
  width: 100%;
  height: 100vh;
  display: flex;
  align-content: space-around;
}

.logo {
  width: 100px;
  margin-top: -50px;
}


.mn-auth {
  width: 100%;
  height: 100vh;
}

.mn-btn {
  background-color: #44B586;
  border: 1px solid #44B586;
  font-size: 12px;
}

.mn-btn:hover {
  background-color: #44B586;
  border: 1px solid #44B586;
  font-size: 12px;
}

.side-logo {
  width: 150px;
  position: absolute;
  bottom: 50px;
  right: 60px;
}

.footer {
  margin-top: 10px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 6%;
  background-color: #112D3D;
}

.login-footer {
  position: absolute;
  bottom: 1px;
  left: 45%;
  font-size: 13px;
  font-weight: 500;
  margin-top: 10px;
}

/* .anticon svg {
  color: #112D3D;
} */

/* Login Area End */







/* Header Start */


.header {
  padding: 10px 32px;
  background-color: #fff !important;
}

.logo-home {
  width: 50px;
}

.hover-dropdown {
  margin-top: 20px;
  color: #000;
}

.ant-space-item {
  white-space: nowrap;
}


/* Header End */







/* Banner Start */

.banner {
  background-image: url(./assets/homeImage.png);
  background-size: cover;
  border-radius: 5px;
  margin: 10px 32px;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 40px;
}

.banner h5 {
  color: #44B586;
}

.banner h2 {
  font-size: 27px;
  color: #fff;
}

.banner h3 {
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 450;
}

.banner img {
  margin-left: 5px;
  width: 12%;
}

.icon {
  color: white;
  font-size: 15px;
  margin-right: 10px;
}



/* Banner End */



/* Common Filter Start */


.plus-img {
  width: 15px;
  margin-right: 10px;
}

.common-filter {
  padding: 30px 30px;
}

.common-filter .ant-input {
  border-radius: 0px !important;
}

.common-filter .ant-input-affix-wrapper .ant-input {
  border-radius: 0px !important;
  height: 30px;
}

.event-trigger {
  padding-left: 10px;
}

.common-filter .ant-picker {
  width: 100% !important;
  border-radius: 0px;
  height: 40px;
}

.common-filter .ant-input {
  height: 40px;
}

.common-filter button {
  background-color: #44B586;
  border: 1px solid #44B586;
  font-size: 13px;
  height: 40px;
  border-radius: 0px;
}

.event-trigger button {
  background-color: #112D3D;
  border: 1px solid #112D3D;
  font-size: 13px;
  height: 40px;
  border-radius: 0px;
}

.event-trigger button:hover {
  color: #44B586;
  background-color: #112D3D;
  border: 1px solid #112D3D;
}

.event-trigger .ant-btn img:hover {
  filter: invert(13%) sepia(14%) saturate(2357%) hue-rotate(158deg) brightness(101%) contrast(93%) !important;
}

.prefix-img span {
  color: #44B586;
}

input::placeholder {
  font-size: 13px;
}

.ant-input {
  font-size: 13px;
}

/* Common Filter End */

/* Create event Start */

.event {
  background-color: #F5F5F5;
  /* height: 100vh; */
  /* height: 91vw; */
  padding: 30px;
}

.form-head-part h2 {
  font-size: 16px;
  font-weight: 600;
}

.form-head-part .ant-form-item-label label {
  font-size: 13px;
}

.form-head-part .ant-form-item {
  margin: 0px !important;
}

.form-head-part .ant-input-prefix {
  font-size: 12.5px;
  color: #112D3D;
  font-weight: 410;
}

.form-head-part .add-lab-btn {
  border: none;
  font-size: 11px;
  background: none;
  color: #44B586;
  text-decoration: underline;
  text-decoration-color: #44B586;
}

.event .ant-picker {
  width: 100%;
}

.event .header {
  padding-bottom: 5px !important;
  padding: 0px !important;
}

.event .header h1 {
  font-size: 20px;
  font-weight: 600;
}

.event .next-btn {
  background-color: #112D3D;
  color: #FFFFFF;
  font-size: 12px;
}

.event .next-btn:hover {
  background-color: #112D3D;
  border: none;
}

.event .arrow-img {
  width: 17%;
}

.event .right {
  margin-right: 10px;
}

.event .left {
  margin-left: 10px;
}

.event .volunteer-btn {
  border: 1px solid #112D3D;
  background-color: #fff;
  font-size: 12px;
  font-family: 420;
  color: #112D3D;
  cursor: pointer;
  border-radius: 4px;
}

.event .volunteer-btn:hover {
  background: none;
}

.event .upload-file {
  border: 1px solid #112D3D;
  background-color: #fff;
  font-size: 11px;
  color: #112D3D;
  padding: 3px 7px;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.event .upload-file img {
  width: 14.3px;
  margin-right: 5px;
}

.event .cancel-btn {
  background-color: #E8E8E8;
  border-radius: 3px;
}

.event .add-btn {
  border: 1px solid #44B586;
  background-color: #fff;
  font-size: 11px;
  color: #44B586;
  padding: 3px 7px !important;
  border-radius: 4px;
}


.event .add-btn img {
  width: 13px;
  margin-right: 5px;
}

.event .top-space {
  margin: 25px 0 5px;
}


.form-head-part .ant-input,
.form-head-part .ant-input-affix-wrapper,
.form-head-part .ant-picker {
  border: 1px solid #112D3D80;
}

.form-head-part .ant-picker-input>input:placeholder-shown {
  display: none;
}

.form-head-part .ant-picker {
  height: 30px !important;
}

.form-head-part .ant-form-item-label label {
  color: #112D3D;
  font-size: 13px;
  padding: 0px !important;
}

.form-head-part .ant-card {
  box-shadow: 0px 0px 2px #00000029;
  border-radius: 6px;
}

.form-head-part .ant-form-item-explain-error {
  font-size: 12px;
  position: absolute !important;
}

.form-head-part .create-add-btn Button {
  background-color: #44B586;
  color: #fff;
  font-size: 13px;
}


.form-head-part .ant-table-thead {
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
}

.form-head-part .ant-table-thead .ant-table-cell {
  white-space: nowrap;
  font-size: 12px;
  background-color: #112D3D;
  color: #fff;
}

.form-head-part .ant-table-tbody .ant-table-cell {
  white-space: nowrap;
  font-size: 11px;
}

.form-head-part .ant-table-tbody .ant-table-cell a {
  white-space: nowrap;
  font-size: 11px;
  color: red;
}

.category-event {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 30px;
  border-bottom: 1px solid #112D3D7F;
  color: #112D3D7F;
  font-size: 15px;
  font-weight: 440;
  align-items: baseline;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.category-event h3 {
  color: #112D3D;
  font-size: 15px;
}

.category-event span {
  color: #112D3D7F;
  font-size: 14px;
  font-weight: 440;
}

.category-event img {
  width: 14.4px;
  margin-right: 5px;
  padding-bottom: 3px;
}

.no-data {
  text-align: center;
  margin: 10% auto;
  display: block;
}

.event-card .no-data {
  text-align: center;
  margin: 10%;
  white-space: nowrap;
}

.log-out {
  color: #112D3D7F;
  font-size: 13px;
  font-weight: 410;
}

.log-out img {
  width: 13px;
  margin-right: 7px;
}

.ant-avatar {
  background-color: #1172AA;
  color: #fff;
}

.ant-pagination-options-size-changer.ant-select {
  display: none;
}

.action {
  color: #F31111;
  text-decoration: underline;
  text-decoration-color: #F31111;
}

 .ant-form-item-explain-error {
  margin-top: -2px;
  font-size: 12px !important;
}



/* Create event End */