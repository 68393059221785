.loader {
    width: 150px;
    position: relative;
    left: 650px;
}

.event-card {
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 50px;
    cursor: pointer;
}

.card-header {
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 20px;
}

.event-card .card-img {
    width: 100%;
    height: 20vh;
    background-size: cover;
}

.event-card Button {
    padding: 3px 10px;
    border-radius: 2px;
    margin-right: 10px;
    margin-top: 5px;
}

.card-body-part {
    background-color: #f7f7f7;
    padding: 10px;
}

.card-body-part:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: 1s;
    cursor: pointer;
}

.card-body-part h3 {
    font-size: 14px;
}

.card-body-part img {
    width: 15px;
    margin-right: 10px
}

.event-text {
    font-size: 12px;
    line-height: 25px;
}

.blue-btn {
    background-color: #1870E2;
    color: #FFFFFF;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid #1870E2;
    padding: 6px 10px;
    margin-top: 10px;
}

.green-btn {
    background-color: #44B586;
    color: #FFFFFF;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid #44B586;
    padding: 6px 10px;
}


.yellow-btn {
    background-color: #EEBF27;
    color: #FFFFFF;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid #EEBF27;
    padding: 6px 10px;
}

.pink-btn {
    background-color: #DC5EB6;
    color: #FFFFFF;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid #DC5EB6;
    padding: 6px 10px;
}

.card-header h2 {
    font-size: 18px;
    font-weight: 550;
}

.card-header button {
    border: 1px solid #44B586;
    font-size: 11.4px;
    font-weight: 420;
    border-radius: 3px;
    color: #44B586;
    background-color: #fff;
    cursor: pointer;
    padding: 3px 15px;
}

/* .card-header Button:hover {
    border: 1px solid red;
    font-size: 12px;
    border-radius: 3px;
    color: red;
} */

.Submit-btn button {
    color: white;
    background-color: #44B586;
}

.submit-Blue-btn {
    background-color: #112D3D;
    color: #fff;
}

.submit-Blue-btn img {
    margin-right: 10px;
    width: 15px;
}

.submitBlue-btn {
    color: white;
    background-color: #112D3D;
    border-radius: 3px;
    margin-top: 10px;
    font-size: 11px;
}

.submitBlue-btn img {
    height: 15px;
    width: 15px;
    margin-right: 10px;


}

.success-cnt {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;
    align-items: center;
}

.success-cnt button {
    height: 50px;
    width: 330px;
    border-radius: 4px;
}

.checkImage {
    width: 70px;
    margin-bottom: 30px;
}

.card-cnt {
    background-color: #44B58629;
    text-align: center;
    color: grey;
    border-style: dashed;
    border-color: #44B586;
    border-radius: 4px;
}

.upload-btn {
    margin-bottom: 20px;
    background-color: #44B586;
    color: white;
}

.upload-btn img {
    width: 50px;
}

.form-head-part .ant-upload.ant-upload-drag .ant-upload {
    padding: 0px;
}

.form-head-part .ant-upload-drag-container {
    padding: 20px;
    background-color: #44B58629;
}